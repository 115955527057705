<template>
	<r-flex class="login-W" align="center">
		<r-flex flx="3" align="center" class="login-C">
			<r-flex align="center" class="r-mb-162">
				<!--img src="@/assets/img/logo-tusdata.png" /-->
			</r-flex>
			<r-flex align="center" class="r-mb-32" v-if="message !== ''">
				<div class="alert-txt-C">
					{{ message }}
				</div>
			</r-flex>
			<r-flex align="center" class="r-mb-16">
				<r-input v-model="user" label="Eposta" class="r-mb-16" />
				<r-input v-model="pass" label="Şifre" type="password" />
			</r-flex>
			<r-flex>
				<r-btn
					bgcolor="#15880d"
					@click="login"
					style="width: 100%"
					:disabled="canClick"
					>Giriş Yap</r-btn
				>
			</r-flex>
		</r-flex>
	</r-flex>
</template>
<script>
	import { onBeforeMount, ref, computed } from 'vue';
	import { useStore } from 'vuex';
	import * as Cookies from 'js-cookie';
	import router from '@/router/';
	export default {
		setup() {
			const store = useStore();
			const user = ref('');
			const pass = ref('');
			const message = ref('');

			const canClick = computed(() => {
				if (user.value !== '' && pass.value !== '') return false;
				else return true;
			});

			const login = async () => {
				let login = await store.dispatch('postDatas', {
					api: 'user/login',
					pos: 'login',
					data: {
						Email: user.value,
						Password: pass.value,
					},
				});
				goLogin(login);
			};
			const goLogin = (l) => {
				console.log(l);
				if (l.data) {
					let d = {
						t: l.data.token,
						n: l.data.name,
						uid: l.data.id,
					};
					Cookies.set('aysarsiv', JSON.stringify(d));
					store.state.loggedin = true;
					store.state.username = l.data.name;
					store.state.userid = l.data.id;
					router.push('/kullanicilar');
				} else {
					message.value = l.msgDetail.msg;
				}
			};
			const changeType = (d) => {
				store.state.usertype = d.type;
			};
			onBeforeMount(async () => {});
			return {
				user,
				pass,
				login,
				message,
				changeType,
				store,
				canClick,
			};
		},
	};
</script>
<style>
	.login-W {
		width: 100%;
		height: 100%;
	}
	.login-C {
		border-radius: 9px;
		padding: 24px;
		-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
		flex: 0 0 auto;
		background-color: #fff;
	}
	.alert-txt-C {
		background-color: rgb(243, 170, 170);
		color: rgb(148, 21, 21);
		width: 100%;
		padding: 16px;
		text-align: center;
		border-radius: 9px;
	}
	.login-select-type-img {
		border: 1px solid #ccc;
		padding: 32px 8px;
		margin: 2%;
		width: 46%;
		cursor: pointer;
		border-radius: 4px;
		flex-basis: 46%;
	}
	.login-select-type-img.selected {
		background-color: #d6f7fb;
	}
	.login-select-type-img:hover {
		background-color: rgb(218, 218, 218);
	}
	.login-select-type-img img {
		width: 100%;
		max-width: 120px;
	}
</style>
